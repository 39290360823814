import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { WEB_URL } from "../api/constants";

function AboutUsNew() {
  const { pathname } = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "EMAAR AWARDS",
      description:
        "In 2024, Xperience Realty was proudly ranked among the top 10 in Emaar's Annual Broker Awards 2023, highlighting its position as a leading Dubai real estate firm and a top Emaar alliance partner. This recognition underscores Xperience Realty's commitment to excellence, supported by Emaar and driven by its dedicated team. The award reaffirms the firm's standing as one of Dubai's premier real estate agencies, a testament to its consistent efforts and high-quality service.",
      image:
        "https://res.cloudinary.com/dkhns25jh/image/upload/v1724746212/xr_media/hlb70npk0vcjijmqqn6m.jpg",
    },
  ];

  const handleSlideChange = (direction) => {
    if (direction === "next") {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    } else {
      setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    }
  };

  // Add useEffect for auto-sliding (optional)
  useEffect(() => {
    const timer = setInterval(() => {
      handleSlideChange("next");
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AboutPage",
            name: "About Us | Xperience Realty | Real Estate Dubai",
            description:
              "Learn more about Xperience Realty, a leading real estate agency in Dubai. Discover our mission, vision, and the team behind our success.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>
      {/* 
      <style>
        {`
          .agent-slider {
            width: 100% !important;
          }
          .agent-slider .slick-track {
            display: flex;
            justify-content: center;
          }
          .agent-slider .slick-slide {
            float: none;
          }
          @media (min-width: 1024px) {
            .agent-slider .slick-slide > div {
              margin: 0 4px;
            }
          }
          @media (max-width: 1023px) {
            .agent-slider .slick-slide > div {
              margin: 0 4px;
            }
          }
        `}
      </style> */}

      <div className="flex flex-col justify-center items-center mt-10 gap-8 mb-8 md:gap-16  md:mb-8">
        {/* <div className=" lg:h-[70dvh] w-full">
          <img
            className="h-full w-full object-cover"
            src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724746380/xr_media/fupdihpsnq6zegrufssi.jpg"
            alt=""
          />
        </div> */}

        {/* Founding Members Section Starts */}
        <div className="w-[90%] md:w-[80%] lg:px-10 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] mx-auto">
          {/* <h2 className="text-center tracking-[0.2rem] text-base md:text-2xl font-bold mb-12 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            FOUNDING MEMBERS
          </h2> */}

          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-12 lg:gap-20 2xl:w-[1200px] mx-auto">
            {/* Left Member */}
            <div className="flex flex-col items-center lg:items-start gap-6">
              <div className="w-full h-[300px] sm:h-[300px] md:h-[300px] lg:h-[auto] md:w-full 2xl:w-[500px] 2xl:h-[500px] overflow-hidden ">
                <img
                  src="https://res.cloudinary.com/dkhns25jh/image/upload/v1738398662/hrygvbv4iyuxytmuz9o6.png"
                  alt="Rohit Singh Rana"
                  className="w-full h-full sm:object-cover object-contain"
                />
              </div>
              <div className="text-center lg:text-left">
                <h3 className="text-xl md:text-2xl font-medium mb-2">
                  Rohit Singh Rana
                </h3>
                <p className="text-gray-600 text-sm md:text-base mb-4">
                  Chairman, CEO & Founder
                </p>
                <p className="text-sm md:text-base font-light text-justify 2xl:w-[500px]">
                  What started as a passion for real estate quickly became a
                  mission to transform the industry. Rohit Singh Rana began his
                  career as a hands-on property consultant, helping clients
                  navigate Dubai’s ever-changing market. His 11 years of
                  experience in the industry, gave him sharp investment insights
                  and ability to close high-value deals soon set him apart,
                  earning him a reputation as one of the top-performing
                  professionals in the field. Now, as the Chairman, Founder and
                  CEO of Xperience Realty, he leads a team of elite property
                  experts, offering clients an easy and strategic approach to
                  real estate investment. With a focus on innovation, integrity,
                  and results, he continues to drive XR’s success and shape the
                  future of Dubai’s property market.
                </p>
              </div>
            </div>

            {/* Right Member */}
            <div className="flex flex-col items-center lg:items-start gap-6">
              <div className="w-full h-[300px] sm:h-[300px]  md:h-[300px] lg:h-[auto] 2xl:w-[500px] 2xl:h-[500px] md:w-full overflow-hidden">
                <img
                  src="https://res.cloudinary.com/dkhns25jh/image/upload/v1738397620/fjrlutupg4myww9a1s1k.jpg "
                  alt="Moutani Goswami"
                  className="w-full h-full sm:object-cover object-contain"
                />
              </div>
              <div className="text-center lg:text-left">
                <h3 className="text-xl md:text-2xl font-medium mb-2">
                  Moutani Goswami
                </h3>
                <p className="text-gray-600 text-sm md:text-base mb-4">
                  Founder & Managing Director
                </p>
                <p className="text-sm md:text-base font-light text-justify 2xl:w-[500px]">
                  With over two decades of experience in the global real estate
                  sphere, Moutani Goswami has mastered both domestic and
                  international markets, spanning India, UAE, Europe, and Asia.
                  Her extensive network and industry expertise laid the
                  foundation for Xperience Realty, a firm driven by innovation
                  and excellence. A visionary leader, she has played a pivotal
                  role in shaping smart communities in India and has worked with
                  some of the most renowned names in real estate. In the UAE,
                  she was instrumental in building strategic alliances,
                  expanding channel networks, and contributing significantly to
                  Emaar’s success. Her global affiliations and deep market
                  insights have positioned Xperience Realty as a premier real
                  estate consultancy.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Founding Members Section Ends */}

        {/* Equity Partners Section Starts */}
        <div className="w-[90%] md:w-[80%] lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] mx-auto ">
          <h2 className="text-center tracking-[0.2rem] text-sm md:text-base font-bold mb-5 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            EQUITY PARTNER
          </h2>

          <div className="flex flex-col items-center gap-6">
            <div className="text-center">
              <h3 className="text-sm md:text-base font-normal mb-2">
                Bal Krishen Rathore
              </h3>
              {/* <p className="text-gray-600 text-sm md:text-sm mb-0">
                Managing Partner & Head of Investment
              </p> */}
            </div>
          </div>
        </div>
        {/* Equity Partners Section Ends */}

        <div className="w-full border-t-2 border-dotted border-gray-400"></div>

        <div className="flex flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[60%] 3xl:w-[45%] gap-4">
          <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            EXPERIENCE THE XPERIENCE
          </h2>
          <p className="text-justify text-sm md:text-base font-light xl:w-[900px] md:text-justify xl:text-center">
            Xperience Realty is where the concept of excellence in Dubai real
            estate consultation reaches new heights. Beyond being a mere
            company, we proudly identify ourselves as a university of knowledge
            and innovation in the world of real estate. Just as our name
            implies, we stand as a shining example of distinction within the
            elite world of high-profile real estate guidance. Building upon a
            foundation of rich experience and an irreplaceable commitment to
            excellence, we have birthed XR as a brand synonymous with quality.
          </p>
        </div>

        {/* <div className="w-full border-t-2 border-dotted border-gray-400"></div> */}

        {/* Content for small/medium screens */}
        <div className="flex flex-col lg:hidden gap-8 items-center md:flex-row md:items-start md:gap-16 md:px-16">
          <div className="flex flex-col items-center gap-4 w-[90%] md:w-[80%]">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center md:text-start ">
              ABOUT US
            </h2>
            <p className="text-sm md:text-base font-light text-justify md:text-justify">
              In a world where properties have stories to tell, we're here to
              lead you through the chapters of opportunity, growth, and
              transformation. Our carefully selected team of real estate agents
              is committed to transforming your dreams into reality. We apply
              the principle of continuous booming and offer an all-encompassing
              curriculum of real estate knowledge that equips you to navigate
              the dynamic industry landscape. Our expertise stretches beyond the
              boundaries of a conventional company; we offer you the chance to
              excel in the world of real estate. As you sink yourself in our
              expansive range of insights, you'll find yourself participating in
              game-changing deals that shape your future.
            </p>
          </div>

          <div className="flex flex-col items-center gap-4 w-[90%] md:w-[80%] ">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center ">
              OUR GOAL
            </h2>
            <p className="text-sm md:text-base font-light text-justify md:text-justify">
              Rendering our passion into action by empowering our agents with
              advanced training, ensuring top-notch customer service and strong
              brand preference. We foster a culture of innovation and excellence
              that equips our team with the skills and knowledge needed to
              exceed industry standards consistently. As we fine-tune our
              approach, we're on a mission to establish ourselves as Dubai's
              unmatched and forward-thinking real estate consultancy, driven to
              deliver the utmost value for all stakeholders within and beyond
              our organization. Committed to integrity and transparency, we
              strive to create lasting relationships and transformative
              experiences that redefine the future of real estate services.
            </p>
          </div>
        </div>

        {/* Content for large screens and above */}
        <div className="hidden lg:grid lg:grid-cols-2  lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] gap-10 lg:gap-40 3xl:gap-64 2xl:w-[80%] mx-auto items-start">
          <div className="col-span-1 gap-4 flex flex-col">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center ">
              ABOUT US
            </h2>
            <p className="text-sm md:text-base font-light text-justify">
              In a world where properties have stories to tell, we're here to
              lead you through the chapters of opportunity, growth, and
              transformation. Our carefully selected team of real estate agents
              is committed to transforming your dreams into reality. We apply
              the principle of continuous booming and offer an all-encompassing
              curriculum of real estate knowledge that equips you to navigate
              the dynamic industry landscape. Our expertise stretches beyond the
              boundaries of a conventional company; we offer you the chance to
              excel in the world of real estate. As you sink yourself in our
              expansive range of insights, you'll find yourself participating in
              game-changing deals that shape your future.
            </p>
          </div>

          <div className="col-span-1  gap-4 flex flex-col">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center ">
              OUR GOAL
            </h2>
            <p className="text-sm md:text-base font-light text-justify">
              Rendering our passion into action by empowering our agents with
              advanced training, ensuring top-notch customer service and strong
              brand preference. We foster a culture of innovation and excellence
              that equips our team with the skills and knowledge needed to
              exceed industry standards consistently. As we fine-tune our
              approach, we're on a mission to establish ourselves as Dubai's
              unmatched and forward-thinking real estate consultancy, driven to
              deliver the utmost value for all stakeholders within and beyond
              our organization. Committed to integrity and transparency, we
              strive to create lasting relationships and transformative
              experiences that redefine the future of real estate services.
            </p>
          </div>
        </div>

        {/* Awards Slideshow Section */}
        <div className="w-full overflow-hidden">
          <h2 className="text-center tracking-[0.1rem] text-base md:text-2xl font-semibold  mb-6 md:mb-6 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            OUR AWARDS
          </h2>
          <div className="relative">
            {/* Images Container */}
            <div className="flex transition-transform duration-500 ease-in-out">
              {slides.map((slide, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <img
                    className="w-full h-[auto] object-cover"
                    src={slide.image}
                    alt={slide.title}
                  />
                </div>
              ))}
            </div>

            {/* Navigation Buttons */}
            <button
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full"
              onClick={() => handleSlideChange("prev")}
            >
              ←
            </button>
            <button
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full"
              onClick={() => handleSlideChange("next")}
            >
              →
            </button>

            {/* Dots Indicator */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
              {[0, 1, 2].map((index) => (
                <button
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    currentSlide === index ? "bg-white" : "bg-white/50"
                  }`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          </div>

          {/* Text Content */}
          <div className="flex transition-transform duration-500 ease-in-out w-full mt-2">
            <div className="flex flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[60%] 3xl:w-[45%] gap-4 mx-auto mt-4">
              {/* <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center">
                {slides[currentSlide].title}
              </h2> */}
              <p className="text-center text-sm md:text-base font-light xl:w-[900px] md:text-center line-clamp-3 ">
                {slides[currentSlide].description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsNew;
