import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";

// import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Shimmer from "./components/Shimmer";
import NotFound from "./components/NotFound";
import ReviewsComponent from "./components/ReviewsComponent";
import { ToastContainer } from "react-toastify";
import ScrollTriggerModal from "./components/ScrollTriggerModal";
import LetXpertsInput from "./components/LetXpertsInput";
import AllDevelopers from "./pages/AllDevelopers";
import AgentDetailsNew from "./components/AgentDetailsNew";

// const Home = lazy(() => import("./pages/Home"));
// const AboutUs = lazy(() => import("./pages/AboutUs"));
// const ContactUs = lazy(() => import("./pages/ContactUs"));
// const Developers = lazy(() => import("./pages/Developers"));
// const XrTeam = lazy(() => import("./pages/XrTeam"));
// const AllProjects = lazy(() => import("./components/AllProjects"));
// const AllCommunities = lazy(() => import("./components/AllCommunities"));
// const AllNewsInsights = lazy(() => import("./pages/AllNewsInsights"));
// const ProjectDetails = lazy(() => import("./components/ProjectDetails"));
// const OurCommunities = lazy(() => import("./components/OurCommunities"));
// const AgentDetails = lazy(() => import("./components/AgentDetails"));
// const RenderContent = lazy(() => import("./components/RenderContent"));
// const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
// const Navbar = lazy(() => import("./components/Navbar"));

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Developers from "./pages/Developers";
import XrTeam from "./pages/XrTeam";
import AllProjects from "./components/AllProjects";
import AllCommunities from "./components/AllCommunities";
import AllNewsInsights from "./pages/AllNewsInsights";
import ProjectDetails from "./components/ProjectDetails";
import OurCommunities from "./components/OurCommunities";
import AgentDetails from "./components/AgentDetails";
import RenderContent from "./components/RenderContent";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Navbar from "./components/Navbar";
import Careers from "./pages/Careers";
import AboutUsNew from "./pages/AboutUsNew";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function WithPopup() {
  return (
    <>
      <Outlet />
      <ScrollTriggerModal triggerPoints={[0.2]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal>
    </>
  );
}

function App() {
  return (
    <Router>
      <Wrapper>
        <Navbar />
        {/* <Suspense fallback={<Shimmer />}> */}
        <Routes>
          <Route element={<WithPopup />}>
            <Route path="/" exact element={<Home />} />
            <Route path="/about-us/" element={<AboutUsNew />} />
            <Route
              path="/contact-us-dubai-real-estate-agency/"
              element={<ContactUs />}
            />
            <Route path="/developer/" element={<AllDevelopers />} />
            {/* <Route path="/label/:id" element={<Developers />} /> */}
            <Route path="/developer/:id/" element={<Developers />} />
            <Route path="/meet-the-xr/" element={<XrTeam />} />
            <Route path="/agent/" element={<XrTeam />} />
            <Route path="/dubai-properties/" element={<AllProjects />} />
            <Route path="/dubai-properties/:type/" element={<AllProjects />} />
            <Route path="/communities/" element={<AllCommunities />} />
            <Route path="/real-estate-news/" element={<AllNewsInsights />} />
            <Route path="/blogs/" element={<AllNewsInsights />} />
            <Route
              path="/real-estate-news/:title/"
              element={<RenderContent />}
            />
            <Route path="/blogs/:title/" element={<RenderContent />} />
            <Route path="/:title/" element={<RenderContent />} />
            <Route path="/notfound/" element={<NotFound />} />

            {/* imagesWithInfo={imagesWithInfo}
                  sections={projectDetails} */}
            <Route path="/property/:title/" element={<ProjectDetails />} />
            <Route path="/propertytype/:type/" element={<AllProjects />} />

            {/*   imagesWithInfo={communityInfo}
                  nearbyCommunities={nearbyCommunities} */}
            <Route path="/area/:title/" element={<OurCommunities />} />
            <Route path="/agent/:agentName/" element={<AgentDetails />} />
            <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
            <Route
              path="/customer-reviews/"
              element={<ReviewsComponent viewAll={true} />}
            />
          </Route>
          <Route path="/careers/" element={<Careers />} />
        </Routes>
        {/* </Suspense> */}
      </Wrapper>
      <Footer />
      <ToastContainer />
    </Router>
  );
}

export default App;
